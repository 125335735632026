import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import { Redirect, Link } from "react-router-dom";
import pleft from "../../assets/img/Landing page-slicing/Pleft.jpeg";
import pright from "../../assets/img/Landing page-slicing/Pright.jpeg";
import Pricing1 from "../../assets/img/Landing page-slicing/pricing-1.svg";
import Pricing2 from "../../assets/img/Landing page-slicing/pricing-2.svg";
import Pricing3 from "../../assets/img/Landing page-slicing/pricing-3.svg";
import close from "../../assets/img/dashboard-Slicing/close.svg";

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      isOpen: false,
      name: "",
      companyName: "",
      companySector: "",
      number: "",
      phoneNumber: "",
      email: "",
      loading: false,
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let obj = {
      name: this.state.name,
      companyName: this.state.companyName,
      companySector: this.state.companySector,
      number: this.state.number,
      phoneNumber: this.state.phoneNumber,
      email: this.state.email,
    };
    this.setState({ loading: true });
    if (
      this.state.name ||
      this.state.companyName ||
      this.state.companySector ||
      this.state.number ||
      this.state.phoneNumber ||
      this.state.email
    ) {
      const response = await axios.post(
        " https://us-central1-chirp-6526c.cloudfunctions.net/sendSms",
        obj
      );
      if (response.data) {
        console.log("resp=>", response.data);
        this.setState({
          name: "",
          companyName: "",
          companySector: "",
          number: "",
          phoneNumber: "",
          email: "",
          loading: false,
        });
      }
    }
  };

  render() {
    return (
      <>
        <div className="bg-white mt- pricing ">
          {/* <Row md="3" sm="1" className="bg-white py-5 px-sm-5 px-0">
            <Col className="mt-auto d-lg-block d-none">
              <div>
                <img src={pleft} width="100%" />
              </div>
            </Col>
            <Col className="m-auto p-0">
              <Card className="text-center m-auto shadow-lg border-0">
                <div className="d-flex justify-content-center pt-0">
                  <h1 className="font-family-2 pr">Pricing</h1>
                </div>
                <div className="p-bg">
                  <h1 className="mb-0 font-weight-bold font-family-p">$10</h1>
                  <p className="pt-0 mb-0">
                    Per User Per Month
                    <br />
                    Unlimited Usage
                  </p>
                  <div className="d-flex div2">
                    <Button
                      className="font-weight-bold mx-2 px-4"
                      color="site-pink"
                      href="/auth/register"
                    >
                      Sign Up
                    </Button>
                    <Button
                      // onClick={this.onPathChange}
                      className="font-weight-bold mx-2 px-4"
                      color="site-pink"
                      // href="/demo"
                      onClick={this.toggle}
                    >
                      Demo
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
            <Col className="mt-auto mb-2 d-lg-block d-none">
              <div>
                <img src={pright} width="100%" />
              </div>
            </Col>
          </Row> */}

          <h1 className="title">Pricing Plans</h1>
          <Container fluid="xl" className="pricing-images">
            <Row
              xs="1"
              sm="1"
              md="2"
              lg="3"
              className="bg-white p-row justify-content-center"
            >
              {/*<Col>
               <Card className="p-card">
                <small className="range">1 - 5 Users</small>
                <h1 className="mb-0 price">$0</h1>
                <p className="pt-0 mb-0">Always Free</p>
                <p className="pt-0 mb-0">For 5 Users</p>
                <Button
                  className="su-btn"
                  color="site-primary"
                  href="/auth/register"
                >
                  Sign Up
                </Button>
              </Card>
            </Col>
			<Col>
              <Card className="p-card">
                <small className="range">6 - 99 Users</small>
                <h1 className="mb-0 price">$9</h1>
                <p className="pt-0 mb-0">Per User</p>
                <p className="pt-0 mb-0">Per Month</p>
                <Button
                  className="su-btn"
                  color="site-primary"
                  href="/auth/register"
                >
                  Sign Up
                </Button>
              </Card>
            </Col>
            <Col>
              <Card className="p-card">
                <small className="range">100 - 249 Users</small>
                <h1 className="mb-0 price">$8</h1>
                <p className="pt-0 mb-0">Per User</p>
                <p className="pt-0 mb-0">Per Month</p>
                <Button
                  className="su-btn"
                  color="site-primary"
                  href="/auth/register"
                >
                  Sign Up
                </Button>
              </Card>
            </Col>
            <Col>
              <Card className="p-card">
                <small className="range">250 - 499 Users</small>
                <h1 className="mb-0 price">$7</h1>
                <p className="pt-0 mb-0">Per User</p>
                <p className="pt-0 mb-0">Per Month</p>
                <Button
                  className="su-btn"
                  color="site-primary"
                  href="/auth/register"
                >
                  Sign Up
                </Button>
              </Card>
            </Col>
            <Col>
              <Card className="p-card">
                <small className="range">500 - 999 Users</small>
                <h1 className="mb-0 price">$6</h1>
                <p className="pt-0 mb-0">Per User</p>
                <p className="pt-0 mb-0">Per Month</p>
                <Button
                  className="su-btn"
                  color="site-primary"
                  href="/auth/register"
                >
                  Sign Up
                </Button>
              </Card>
            </Col>
            <Col>
              <Card className="p-card">
                <small className="range">1,000 + Users</small>
                <h1 className="mb-0 price">$5</h1>
                <p className="pt-0 mb-0">Per User</p>
                <p className="pt-0 mb-0">Per Month</p>
                <Button
                  className="su-btn"
                  color="site-primary"
                  href="/auth/register"
                >
                  Sign Up
                </Button>
              </Card> 
			  </Col>
		*/}
              {/* <h3 className="display-2 custom">
                      Free
                    </h3> */}
              {/* <span className="currency">$</span> */}
              {/* <span className="period">/month</span> */}

              {/* <Col className="">
                <div className="card text-center rounded shadow">
                  <div className="card-header">

                    <h3 className="display-2 custom mb-0">
                      <span className="">Free</span>
                    </h3>{" "}
                    <span className="period">30 Day Free Trial</span>
                  </div>
                  <div className="card-block mt-auto">
                    <h4 className="card-title">Trial Plan</h4>
                    <ul className="list-group">
                      <li className="list-group-item">Unlimited Text</li>
                      <li className="list-group-item">Unlimited Audio</li>
                      <li className="list-group-item">Unlimited Video</li>
                      <li className="list-group-item">
                        Artificial Intelligence
                      </li>
                    </ul>
                    <a href="#" className="btn btn-gradient mt-2 rounded">
                      Choose Plan
                    </a>
                  </div>
                </div>
              </Col> */}

              {/* <Col className="">
                <div className="card text-center rounded shadow">
                  <div className="card-header">

                    <h3 className="display-2  mb-0">
                      <span className="currency">$</span>10
                    </h3>{" "}
                    <span className="period">Per User Per Month</span>
                  </div>
                  <div className="card-block mt-auto">
                    <h4 className="card-title">Business Plan</h4>
                    <ul className="list-group">
                      <li className="list-group-item">Unlimited Text</li>
                      <li className="list-group-item">Unlimited Audio</li>
                      <li className="list-group-item">Unlimited Video</li>
                      <li className="list-group-item">
                        Artificial Intelligence
                      </li>
                    </ul>
                    <a href="#" className="btn btn-gradient mt-2 rounded">
                      Choose Plan
                    </a>
                  </div>
                </div>
              </Col> */}

              {/* <Col className="">
                <div className="card text-center rounded shadow">
                  <div className="card-header">
                    <h3 className="display-2 custom">
                 
                      Enterprise
                    
                    </h3>{" "}
                    <span className="period">Contact Us</span>
                  </div>
                  <div className="card-block mt-auto">
                    <h4 className="card-title">Enterprise Plan</h4>
                    <ul className="list-group">
                      <li className="list-group-item">Unlimited Text</li>
                      <li className="list-group-item">Unlimited Audio</li>
                      <li className="list-group-item">Unlimited Video</li>
                      <li className="list-group-item">
                        Artificial Intelligence
                      </li>
                    </ul>
                    <a href="#" className="btn btn-gradient mt-2 rounded">
                      Choose Plan
                    </a>
                  </div>
                </div>
              </Col> */}
              {[
                {
                  image: Pricing1,
                  btn: (
                    <Button
                      href="/auth/register"
                      color="site-primary"
                      className="pricing-btn"
                    >
                      Try Now
                    </Button>
                  ),
                  body: (
                    <div className="price-body" style={{ width: "52%" }}>
                      Try merk for free for 30 days and see why we are the
                      number one app for mobile communication.
                    </div>
                  ),
                  header: {
                    title: "FREE",
                    subtext: "30 Day Trial",
                  },
                },
                {
                  image: Pricing3,
                  btn: (
                    <Button
                      href="#"
                      className="pricing-btn"
                      color="site-primary"
                    >
                      Sign Up
                    </Button>
                  ),
                  body: (
                    <div className="price-body" style={{ width: "35%" }}>
                      ✔ Messaging
                      <br />
                      ✔ Audio Call
                      <br />
                      ✔ Video Call
                      <br />
                      ✔ Push to Talk
                      <br />
                      ✔  Google Gemini
                    </div>
                  ),
                  header: {
                    title: "$20",
                    subtext: "Per User Per Month",
                  },
                },
                // {
                //   image: Pricing2,
                //   btn: (
                //     <Button
                //       href="/demo"
                //       className="pricing-btn"
                //       color="site-primary"
                //     >
                //       {" "}
                //       Call Us
                //     </Button>
                //   ),
                //   body: (
                //     <div className="price-body">
                //       White label merk for your enterprise corporation and
                //       create your own internal app for mobile communication.
                //     </div>
                //   ),
                //   header: {
                //     title: "CALL",
                //     subtext: "Custom Pricing",
                //   },
                // },
              ].map((e, key) => {
                return (
                  <Col key={key} className="p-5">
                    <div className="d-flex p-0 justify-content-center position-relative">
                      <div className="price-header">
                        <h3 className="m-0">{e.header.title}</h3>
                        <h5 className="m-0">{e.header.subtext}</h5>
                      </div>
                      <img src={e.image} className="w-100" alt="pricing-page" />
                      {e.body}
                      {e.btn}
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
        {/* <div className=" mt-5 pricing">
        <h1 className="font-family-2">Pricing</h1>
        <h2 className="mb-0 font-weight-bold font-family-p">$10</h2>
        <h1 className="pt-0">
          Per User Per Month
          <br />
          Unlimited Usage
        </h1>
        <div className="d-flex">
          <Button className="font-family-2" color="site-primary">
            Sign Up
          </Button>
          <Button
            // onClick={this.onPathChange}
            className="font-family-2"
            color="site-primary"
            href="/demo"
          >
            Schedule A Demo
          </Button>
        </div>
      </div> */}
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody className="p-4 mx- bg-site-primary  shadow">
            <div className="text-right d-flex">
              <span className="ml-auto mt-auto mb-auto" onClick={this.toggle}>
                <img src={close} alt="icon" width="40px" className="close-AM" />
              </span>
            </div>

            <h1
              className="font-family-2 text-center text-white"
              style={{ marginTop: "-50px" }}
            >
              Schedule A Demo
            </h1>
            {/* <Card> */}
            <Form onSubmit={(e) => this.handleSubmit(e)}>
              <FormGroup>
                <Input
                  placeholder="Your Name"
                  name="name"
                  type="name"
                  value={this.state.name}
                  required
                  onChange={(e) => this.handleChange(e)}
                  className="py-4 bg-site-secondary border-0"
                />{" "}
              </FormGroup>
              <FormGroup>
                <Input
                  placeholder="Company Name"
                  name="companyName"
                  type="name"
                  value={this.state.companyName}
                  required
                  onChange={(e) => this.handleChange(e)}
                  className="py-4 bg-site-secondary border-0"
                />{" "}
              </FormGroup>
              <FormGroup>
                <Input
                  placeholder="Company Sector"
                  name="companySector"
                  type="name"
                  value={this.state.companySector}
                  required
                  onChange={(e) => this.handleChange(e)}
                  className="py-4 bg-site-secondary border-0"
                />{" "}
              </FormGroup>
              <FormGroup>
                <Input
                  type="number"
                  name="number"
                  placeholder="Number of Employees"
                  value={this.state.number}
                  required
                  onChange={(e) => this.handleChange(e)}
                  className="py-4 bg-site-secondary border-0"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="number"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={this.state.phoneNumber}
                  required
                  onChange={(e) => this.handleChange(e)}
                  className="py-4 bg-site-secondary border-0"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={this.state.email}
                  required
                  onChange={(e) => this.handleChange(e)}
                  className="py-4 bg-site-secondary border-0"
                />
              </FormGroup>
              <Button
                color="site-secondary"
                className="w-50 py-3 m-auto w-100"
                type="submit"
              >
                {" "}
                {this.state.loading ? <Spinner /> : "Submit"}
              </Button>
            </Form>
            {/* </Card> */}
          </ModalBody>
        </Modal>
      </>
    );
  }
}
export default Pricing;
